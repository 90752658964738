import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { VideoPlayer } from '@graphland/react-video-player';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import './MediaPage.css';

const MediaPage = ({ userId }) => {
  const [mediaItems, setMediaItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [processingVideoId, setProcessingVideoId] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation(); // Initialize useLocation

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await axios.get(`https://cloud.leaknews.net/getmedia_cloud.php?user_Id=${userId}`);
        setMediaItems(response.data);
      } catch (err) {
        setError('Error fetching media items.');
      } finally {
        setLoading(false);
      }
    };

    fetchMedia();
  }, [userId]);

  useEffect(() => {
    // Extract media ID from URL when the component mounts
    const params = new URLSearchParams(location.search);
    const mediaId = params.get('mediaId');
    if (mediaId) {
      const mediaItem = mediaItems.find(item => item.id === parseInt(mediaId));
      if (mediaItem) {
        setSelectedMedia(mediaItem.file_path); // Set the selected media URL
      }
    }
  }, [location.search, mediaItems]);

  const handleMediaClick = (mediaUrl, mediaId, ffmpegStatus) => {
    if (ffmpegStatus === 'processing started') {
      setProcessingVideoId(mediaId);
    } else {
      setSelectedMedia(mediaUrl);
      // Add media ID parameter to the URL
      navigate(`?mediaId=${mediaId}`);
    }
  };

  const closeModal = () => {
    setSelectedMedia(null);
    setProcessingVideoId(null);
    // Remove media ID parameter from URL
    navigate(window.location.pathname);
  };

  const isImage = (url) => /\.(jpg|jpeg|png|gif|bmp|svg)$/.test(url);

  if (loading) return <div className="text-center loading">Loading...</div>;
  if (error) return <div className="text-danger">{error}</div>;

  return (
    <div className="media-page">
      <h1 className="text-center">Media Items</h1>
      <div className="media-grid">
        {mediaItems.map(item => (
          <div key={item.id} className="media-card" onClick={() => handleMediaClick(item.file_path, item.id, item.ffmpeg_status)}>
            {item.thumbnail_path ? (
              <img src={item.thumbnail_path} alt={`Thumbnail for ${item.id}`} className="thumbnail" />
            ) : (
              <img src={item.file_path} alt={`Media item ${item.id}`} className="thumbnail" />
            )}
            {item.ffmpeg_status === 'processing started' && (
              <div className="status">
                <p>Processing started...</p>
              </div>
            )}
          </div>
        ))}
      </div>

      {selectedMedia && (
        <div className="modal show" tabIndex="-1" role="dialog" style={{ display: 'block' }} onClick={closeModal}>
          <div className="modal-dialog" role="document" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{isImage(selectedMedia) ? 'Image Viewer' : 'Video Player'}</h5>
                <span onClick={closeModal} className='close-span'><i className="fas fa-times"></i></span>
              </div>
              <div className="modal-body">
                {isImage(selectedMedia) ? (
                  <img src={selectedMedia} alt="Selected media" className="img-fluid" />
                ) : (
                  <VideoPlayer
                    theme="forest"
                    width={600}
                    height={400}
                    autoPlay={false}
                    loop={false}
                    sources={[{ src: selectedMedia, type: 'video/mp4' }]}
                    controls
                    onReady={() => console.log('Video player is ready!')}
                    disabled={processingVideoId !== null}
                    className='video-js'
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaPage;
