// src/App.js

import React from 'react';
import MediaPage from './MediaPage';
import './MediaPage.css';
// src/index.js or src/App.js
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <MediaPage userId={1} /> {/* Replace with dynamic user ID as needed */}
    </div>
  );
}

export default App;
